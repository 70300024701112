<template>
  <div style="margin-top: 60px;">
    <h1 style="text-align: center">Sign In</h1>
    <p style="text-align: center">Welcome! Please Sign In to your account.</p>
    <br />
    <div>
      <a-form
        id="components-form-demo-normal-login"
        :form="form"
        class="login-form"
        @submit="handleSubmit"
      >
        <a-form-item>
          <a-input
            :disabled="whileSignIn"
            size="large"
            v-decorator="[
              'username',
              {
                rules: [
                  { required: true, message: 'Please input your username!' },
                ],
              },
            ]"
            placeholder="Username"
          >
            <a-icon
              slot="prefix"
              type="user"
              class="auth-icon"
              style="font-size: 1rem; color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            :disabled="whileSignIn"
            size="large"
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: 'Please input your Password!' },
                ],
              },
            ]"
            type="password"
            placeholder="Password"
          >
            <a-icon
              slot="prefix"
              type="lock"
              class="auth-icon"
              style="font-size: 1rem; color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <div style="text-align: right">
          <router-link to="/auth/forgot-password">Forgot Password?</router-link>
        </div>
        <br />
        <a-form-item>
          <a-button
            :disabled="whileSignIn"
            size="large"
            type="primary"
            html-type="submit"
            class="login-form-button"
            style="width: 100%; background: #5168ea; border-color: #5168ea"
          >
            <a-icon type="lock" style="color: white" />
            <span v-if="whileSignIn" style="color: white">Signing In...</span>
            <span v-if="!whileSignIn" style="color: white">Sign In</span>
          </a-button>
        </a-form-item>
        <br />
<!--        <div style="text-align: center">Or</div>-->
<!--        <br />-->
<!--        <div style="text-align: center; font-size: 1.1rem">-->
<!--          Register your business <router-link to="/auth/register">here</router-link>-->
<!--        </div>-->
      </a-form>
    </div>
  </div>
</template>

<script>
import { AuthApi } from "../../services";
import jwt from "jsonwebtoken";

export default {
  data() {
    return {
      whileSignIn: false
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            this.whileSignIn = true;
            const response = await AuthApi.signin(values);
            this.whileSignIn = false;
            const decodedUser = jwt.decode(response.data);
            localStorage.setItem("token", response.data);
            localStorage.setItem("profile", JSON.stringify(decodedUser));
            console.log(decodedUser);
            this.$message.success(`Welcome ${decodedUser.username}`);
            this.$router.push("/locations");
          } catch (err) {
              this.whileSignIn = false;
              console.log(err);
            if (err.response.data.message) {
              this.$message.error(err.response.data.message);
            } else {
              this.$message.error(`Invalid Credentials`);
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.ant-input-affix-wrapper >>> .ant-input,
.ant-input-affix-wrapper >>> .ant-input:focus,
.ant-input-affix-wrapper >>> .ant-input:hover {
  box-shadow: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid #4c5798;
  border-radius: 0;
  outline: none !important;
}
a {
  color: #5168ea !important;
}
.auth-icon {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  margin-left: -10px;
  display: inline-block;
  margin-top: -6px;
}
</style>
