<template>
  <div>
    <h1 style="text-align: center;">Register</h1>
    <p style="text-align: center;">Welcome! Please Register your Business.</p>
    <br />
    <div>
        <a-form
            id="components-form-demo-normal-login"
            :form="form"
            class="login-form"
            @submit="handleSubmit"
        >
            <a-row>
                <a-col :span="8">
                    <a-form-item>
                        <a-input
                            :disabled="whileSaving"
                            size="large"
                            v-decorator="[
                            'companyName',
                            { rules: [{ required: true, message: 'Please input Business name!' }] },
                            ]"
                            placeholder="Business Name*"
                        >
                            <a-icon slot="prefix" type="bulb" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
                        </a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="8" :offset="1">
                    <a-form-item>
                        <a-input
                            :disabled="whileSaving"
                            v-decorator="['firstName',
                            { rules: [{ required: true, message: 'Please input First Name!' }] }
                            ]"
                            size="large"
                            placeholder="First Name*"
                        >
                            <a-icon slot="prefix" type="user" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
                        </a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="7">
                    <a-form-item>
                        <a-input
                            :disabled="whileSaving"
                            v-decorator="['lastName',
                            { rules: [{ required: true, message: 'Please input Last Name!' }] }
                            ]"
                            size="large"
                            placeholder="Last Name*"
                        >
                            <a-icon slot="prefix" type="user" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
                        </a-input>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-form-item>
            <a-input
                :disabled="whileSaving"
                size="large"
                v-decorator="[
                'email',
                { rules: [{ required: true, message: 'Please input your Email!' }] },
                ]"
                placeholder="Email*"
            >
                <a-icon slot="prefix" type="user" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
            </a-input>
            </a-form-item>
            <a-form-item>
            <a-input
                :disabled="whileSaving"
                size="large"
                v-decorator="[
                'username',
                { rules: [{ required: true, message: 'Please input your Username!' }] },
                ]"
                placeholder="Username*"
            >
                <a-icon slot="prefix" type="user" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
            </a-input>
            </a-form-item>
            <a-form-item>
            <a-input
                :disabled="whileSaving"
                size="large"
                v-decorator="[
                'password',
                { rules: [{ required: true, message: 'Please input your Password!' }] },
                ]"
                type="password"
                placeholder="Password*"
            >
                <a-icon slot="prefix" type="lock" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
            </a-input>
            </a-form-item>
            <a-form-item>
            <a-input
                :disabled="whileSaving"
                v-decorator="['contactNumber',
                { rules: [{ required: true, message: 'Please input your Contact Number!' }] },]"
                size="large"
                placeholder="Contact Number*"
            >
                <a-icon slot="prefix" type="number" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
            </a-input>
            </a-form-item>
            <a-form-item>
            <a-input
                :disabled="whileSaving"
                v-decorator="['address']"
                size="large"
                placeholder="Street Address"
            >
                <a-icon slot="prefix" type="environment" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
            </a-input>
            </a-form-item>
            <a-row>
                <a-col :span="8">
                    <a-form-item>
                        <a-input
                            :disabled="whileSaving"
                            size="large"
                            v-decorator="['city']"
                            placeholder="City"
                        >
                            <a-icon slot="prefix" type="environment" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
                        </a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="7" :offset="1">
                    <a-form-item>
                        <a-input
                            :disabled="whileSaving"
                            v-decorator="['state']"
                            size="large"
                            placeholder="State"
                        >
                            <a-icon slot="prefix" type="environment" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
                        </a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="7" :offset="1">
                    <a-form-item>
                        <a-input
                            :disabled="whileSaving"
                            v-decorator="['zipcode']"
                            size="large"
                            placeholder="Zip Code"
                        >
                            <a-icon slot="prefix" type="environment" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
                        </a-input>
                    </a-form-item>
                </a-col>
            </a-row>
            <br />
            <a-form-item>
            <a-button 
                :disabled="whileSaving"
                size="large" type="primary" html-type="submit" class="login-form-button" style="width: 100%;background: #5168EA;border-color: #5168EA">
                <a-icon type="lock" style="color: white" />
                <span v-if="whileSaving" style="color: white">Registering...</span>
                <span v-if="!whileSaving" style="color: white">Register</span>
            </a-button>
            </a-form-item>
            <div style="text-align: center">
                Or
            </div>

            <div style="text-align: center;font-size: 1.1rem;">
                Already have an account? <router-link to="/auth">Sign In</router-link>
            </div>
        </a-form>
    </div>
  </div>
</template>

<script>
import { AuthApi } from '../../services'

export default {
    data() {
        return {
            whileSaving: false
        }
    },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_login' });
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
            try {
                this.whileSaving = true;
                await AuthApi.register(values);
                this.whileSaving = false;
                this.$router.push("/auth");
                this.$message.success("Your business registered successfully. Please activate your account and continue.");
            } catch(err) {
                this.whileSaving = false;
                if (err.response.data.message) {
                    this.$message.error(err.response.data.message);
                }
            }
        }
      });
    },
  },
}
</script>

<style scoped>
.ant-input-affix-wrapper >>> .ant-input, .ant-input-affix-wrapper >>> .ant-input:focus,
.ant-input-affix-wrapper >>> .ant-input:hover{
    box-shadow: none;
    background: transparent;
    border: none;
    border-bottom: 1px solid #4c5798;
    border-radius: 0;
    outline: none !important;
}
a {
    color: #5168EA !important;
}
.auth-icon {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.25);
    vertical-align: middle;
    margin-left: -10px;
    display: inline-block;
    margin-top: -8px;
}
</style>