<template>
  <div>
    <h1 style="text-align: center">Activate Account</h1>
    <br />
    <br />
    <div style="color: orangered; text-align:center;font-size: 1.5rem">{{ message }}</div>
    <br />
    <br />
    <div style="text-align:center;" v-if="inprogress">Activating your account...</div>
    <div style="text-align:center;" v-if="!inprogress"><router-link to="/auth">Sign In</router-link></div>
  </div>
</template>

<script>
import { AuthApi } from "../../services";
export default {
  data() {
    return {
        inprogress: false,
      message: null,
    };
  },
  async mounted() {
    await this.activate();
  },
  methods: {
    async activate() {
        this.inprogress = true;
      try {
        await AuthApi.activate(this.$route.params.token);
        this.inprogress = false;
        this.$message.success("Your account activated successfully");
        this.$message.success("Please login to continue");
        this.$router.push("/auth");
      } catch (err) {
          this.inprogress = false;
          console.log(err);
        if (err.response.data && err.response.data.message) {
          this.$message.error(err.response.data.message);
          this.message = err.response.data.message;
        }
      }
    },
  },
};
</script>

<style scoped>
</style>