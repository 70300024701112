<template>
  <div>
    <h1 style="text-align: center;">Forgot Password</h1>
    <p style="text-align: center;">Enter your email and we send you a password reset link.</p>
    <br />
    <div>
        <a-form
            id="components-form-demo-normal-login"
            :form="form"
            class="login-form"
            @submit="handleSubmit"
        >
            <a-form-item>
            <a-input
                :disable="whileSending"
                size="large"
                v-decorator="[
                'email',
                { rules: [{ required: true, message: 'Please input your Username!' }] },
                ]"
                placeholder="Username"
            >
                <a-icon slot="prefix" type="user" class="auth-icon" style="font-size: 1rem;color: rgba(0,0,0,.25)" />
            </a-input>
            </a-form-item>
            <br />
            <a-form-item>
            <a-button size="large" type="primary" html-type="submit" class="login-form-button" style="width: 100%;background: #5168EA;border-color: #5168EA">
                <a-icon type="lock" style="color: white" />
                <span v-if="!whileSending">Request</span>
                <span v-if="whileSending">Please wait...</span>
            </a-button>
            </a-form-item>
            <br />
            <div style="text-align: center;font-size: 1.1rem;">
                Remembered your password? <router-link to="/auth">Sign In</router-link>
            </div>
        </a-form>
    </div>
  </div>
</template>

<script>
import { AuthApi } from '../../services';

export default {
  data() {
    return {
      whileSending: false
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_login' });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            this.whileSending = true;
            await AuthApi.forgotPassword(values.email);
            this.$message.success('Reset Password Link sent successfully');
          } catch(err) {
            console.log(err.response.data);
            if (err.response.data && err.response.data.message) {
              this.$message.error(err.response.data.message);
            } else {
              this.$message.error('Something went wrong. Please contact administrator');
            }
          }
          this.whileSending = false;
        }
      });
    },
  },
}
</script>

<style scoped>
.ant-input-affix-wrapper >>> .ant-input, .ant-input-affix-wrapper >>> .ant-input:focus,
.ant-input-affix-wrapper >>> .ant-input:hover{
    box-shadow: none;
    background: transparent;
    border: none;
    border-bottom: 1px solid #4c5798;
    border-radius: 0;
    outline: none !important;
}
a {
    color: #5168EA !important;
}
.auth-icon {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.25);
    vertical-align: middle;
    margin-left: -10px;
    display: inline-block;
    margin-top: -8px;
}
</style>