var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v("Reset Password")]),_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Set a strong password to your account")]),_c('br'),_c('div',[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: 'Please input your Password!' } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: 'Please input your Password!' },\n              ],\n            },\n          ]"}],attrs:{"size":"large","type":"password","placeholder":"Password"}},[_c('a-icon',{staticClass:"auth-icon",staticStyle:{"font-size":"1rem","color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirmPassword',
            {
              rules: [
                {
                  required: true,
                  message: 'Please input your Confirm Password!',
                } ],
            } ]),expression:"[\n            'confirmPassword',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please input your Confirm Password!',\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"large","type":"password","placeholder":"Confirm Password"}},[_c('a-icon',{staticClass:"auth-icon",staticStyle:{"font-size":"1rem","color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('br'),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",staticStyle:{"width":"100%","background":"#5168ea","border-color":"#5168ea"},attrs:{"size":"large","type":"primary","html-type":"submit"}},[_c('a-icon',{staticStyle:{"color":"white"},attrs:{"type":"lock"}}),_c('span',[_vm._v("Reset Password")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }