<template>
  <div>
    <h1 style="text-align: center">Reset Password</h1>
    <p style="text-align: center">Set a strong password to your account</p>
    <br />
    <div>
      <a-form
        id="components-form-demo-normal-login"
        :form="form"
        class="login-form"
        @submit="handleSubmit"
      >
        <a-form-item>
          <a-input
            size="large"
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: 'Please input your Password!' },
                ],
              },
            ]"
            type="password"
            placeholder="Password"
          >
            <a-icon
              slot="prefix"
              type="lock"
              class="auth-icon"
              style="font-size: 1rem; color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            size="large"
            v-decorator="[
              'confirmPassword',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please input your Confirm Password!',
                  },
                ],
              },
            ]"
            type="password"
            placeholder="Confirm Password"
          >
            <a-icon
              slot="prefix"
              type="lock"
              class="auth-icon"
              style="font-size: 1rem; color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <br />
        <a-form-item>
          <a-button
            size="large"
            type="primary"
            html-type="submit"
            class="login-form-button"
            style="width: 100%; background: #5168ea; border-color: #5168ea"
          >
            <a-icon type="lock" style="color: white" />
            <span>Reset Password</span>
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { AuthApi } from "../../services";

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            const { activationid } = this.$route.params;
            await AuthApi.resetPassword(activationid, values.password);
            this.$message.success('Password reset successfully');
            this.$router.push("/auth");
          } catch(err) {
            if (err.response.data && err.response.data.message) {
              this.$message.error(err.response.data.message);
            } else {
              this.$message.error('Something went wrong. Please contact administrator');
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.ant-input-affix-wrapper >>> .ant-input,
.ant-input-affix-wrapper >>> .ant-input:focus,
.ant-input-affix-wrapper >>> .ant-input:hover {
  box-shadow: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid #4c5798;
  border-radius: 0;
  outline: none !important;
}
a {
  color: #5168ea !important;
}
.auth-icon {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  margin-left: -10px;
  display: inline-block;
  margin-top: -8px;
}
</style>